<!--
 * @Author: 月魂
 * @Date: 2021-11-15 16:53:56
 * @LastEditTime: 2022-09-20 11:06:04
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \silk-road\src\components\Perform.vue
-->
<template>
  <div class="perform" id="perform">
    <div class="perform-cont">
      <div class="box">
        <div class="header">
          <div class="title">
            <div class="zh">文艺<span class="zh-text">演出</span></div>
            <div class="en">Artistic Performance</div>
          </div>
        </div>
        <div class="list">
          <div class="part" v-for="(part, index) in getPart" :key="index" @click="jumpToPart(part)">
            <img :src="part.rowUrl" alt="" class="part-img">
            <div class="part-title">{{ part.title }}</div>
          </div>
          <div class="item" v-for="item in getList" :key="item.id + 3" @click="jumpToDetail(item.id)">
            <img :src="item.rowUrl" alt="" class="item-img">
            <div class="item-text">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-time">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div class="getMore">
          <div class="btn" @click="jump">查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dayjs from 'dayjs'

export default {
  name: 'Perform',
  computed: {
    getPart: function() {
      let list = [];
      Object.values(this.$t('perform.part')).forEach(part => {
        if (part.type === 'live') {
          if (part.onShow) list.push(part)
        } else {
          list.push(part)
        }
      })
      return list
    },
    getList: function() {
      let list = []
      Object.values(this.$t('perform.list')).forEach(item => {
        if (item.rowUrl) {
          list.push(item)
        }
      })
      return list;
    }
  },
  methods: {
    jumpToPart(param) {
      if (param.type === 'perform') {
        this.$router.push(`/perform-detail/${param.performId}`)
      } else if (param.type === 'live') {
        this.$router.push('/live-list')
      } else {
        this.$router.push({ path: '/specify-list', query: { title: param.title, enTitle: param.enTitle, performType: param.performType }})
      }
    },
    jump() { // 需要接受参数以辨别剧目
      this.$router.push('/perform')
    },
    jumpToDetail(id) {
      this.$router.push(`/perform-detail/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.perform {
  position: relative;
  padding: 44px 16px 0;
  color: #fff;
  .perform-cont {
    max-width: 1200PX;
    margin: auto;
    .box {
      width: 100%;
      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: SourceHanSerifCN-Bold;
          .zh {
            font-size: 42px;
            font-weight: bold;
            color: #4d4d4d;
            .zh-text {
              color: #FD4A22;
              font-size: 28px;
            }
          }
          .en {
            color: #4D4D4D;
            font-size: 14px;
            font-family: Arial;
            text-align: center;
          }
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 16px;
        .part {
          width: 48%; // 165px, list宽为343px，百分比为48%
          height: 190px;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          &:nth-child(n + 3) {
            width: 100%;
            margin-top: 12px;
          }
          .part-img {
            display: block;
            width: 100%;
            height: 100%;
          }
          .part-title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 12px;
            font-size: 17px;
            color: rgba(255, 255, 255, 0.9);
            background: rgba(0, 0, 0, 0.6);
            font-family: SourceHanSansSC-Medium;
          }
        }
        .item {
          width: 31%; // 106px
          margin-top: 12px;
          .item-img {
            display: block;
            width: 100%;
            height: 131px;
            border-radius: 10px;
            overflow: hidden;
          }
          .item-text {
            width: 100%;
            margin-top: 6px;
            font-family: SourceHanSansSC-Medium;
            .item-title {
              font-size: 16px;
              color: #333333;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .item-time {
              font-size: 12px;
              color: #999;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .getMore {
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          width: 80px;
          height: 24px;
          border: 1px solid #4D4D4D;
          border-radius: 12px;
          color: #4D4D4D;
          font-family: SourceHanSansSC-Medium;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
        }
      }
    }
  }
}
</style>
