/*
 * @Author: 月魂
 * @Date: 2022-09-02 13:47:46
 * @LastEditTime: 2022-09-20 10:58:26
 * @LastEditors: 月魂
 * @Description: router
 * @FilePath: \silk-road\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../i18n/i18n'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/intro',
    name: 'Intro',
    component: () => import('../views/Intro.vue')
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import('../views/Schedule.vue')
  },
  {
    path: '/schedule/list',
    name: 'ScheduleList',
    component: () => import('../views/ScheduleList.vue')
  },
  {
    path: '/perform',
    name: 'PerformList',
    component: () => import('../views/perform/list.vue')
  },
  {
    path: '/live-list',
    name: 'LiveList',
    component: () => import('../views/perform/live-list.vue')
  },
  {
    path: '/exhibition',
    name: 'ExhibitionList',
    component: () => import('../views/exhibition/list.vue')
  },
  {
    path: '/activities',
    name: 'ActivitiesList',
    component: () => import('../views/activities/list.vue')
  },
  {
    path: '/activity-list/:id',
    name: 'ActivityList',
    component: () => import('../views/activities/activity-list.vue')
  },
  {
    path: '/specify-list',
    name: 'SpecifyList',
    component: () => import('../views/activities/perform-list.vue')
  },
  {
    path: '/exhibition-detail/:id',
    name: 'ExhibitionDetail',
    component: () => import('../views/exhibition/detail.vue')
  },
  {
    path: '/live/:id',
    name: 'Live',
    component: () => import('../views/perform/live.vue')
  },
  {
    path: '/activity-detail/:id',
    name: 'ActivitiesDetail',
    component: () => import('../views/activities/detail.vue')
  },
  {
    path: '/perform-detail/:id',
    name: 'PerformDetail',
    component: () => import('../views/perform/detail.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition;
    // } else {
    //   return { x: 0, y: 0 }
    // }
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 100)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  if (i18n.te('perform')) {
    next()
  } else {
    const zhReq = [
      '/json/setting-zh.json',
      '/json/perform-zh.json',
      '/json/exhibition-zh.json',
      '/json/activities-zh.json',
      '/json/news-zh.json'
    ];
    const enReq = [
      '/json/setting-en.json',
      '/json/perform-en.json',
      '/json/exhibition-en.json',
      '/json/activities-en.json',
      '/json/news-en.json'
    ]
    await axios.all(zhReq.map(url => axios.get(url, { headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }}))).then(resArr => {
      resArr.map(res => i18n.mergeLocaleMessage('zh', JSON.parse(JSON.stringify(res.data))))
    })
    await axios.all(enReq.map(url => axios.get(url, { headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }}))).then(resArr => {
      resArr.map(res => i18n.mergeLocaleMessage('en', res.data))
    })
    next()
  }
  
})

export default router
