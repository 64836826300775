<!--
 * @Author: 月魂
 * @Date: 2021-12-06 11:36:00
 * @LastEditTime: 2022-09-16 17:38:26
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \silk-road\src\components\Footer.vue
-->
<template>
  <div class="footer">
    <!-- <div class="name part">
      <div class="title">活动名称</div>
      <div class="zh">第八届丝绸之路国际艺术节</div>
      <div class="en">The 8th Silk Road International Arts Festival</div>
    </div> -->

    <!-- <div class="slogan part">
      <div class="title">本届slogan</div>
      <div class="zh">共谱和平之曲 高唱丝路欢歌</div>
      <div class="en">Composing the Melody of Peace  Chanting the Song of Silk Road</div>
    </div> -->

    <!-- <div class="price part">
      <div class="title">价值理念</div>
      <div class="zh">和平|和谐|合作</div>
      <div class="en">Peace·Harmony·Cooperation</div>
    </div> -->

    <div class="host part">
      <div class="zh titlezh">主办</div>
      <div class="en titleen">HOSTED BY</div>
      <div class="zh">中华人民共和国文化和旅游部</div>
      <div class="en">Ministry of Culture and Tourism of the People`s Republic of China</div>
      <div class="zh">陕西省人民政府</div>
      <div class="en">Shaanxi Provincial People`s Government</div>
    </div>

    <div class="organize part">
      <div class="zh titlezh">承办</div>
      <div class="en titleen">ORGANIZED BY</div>
      <div class="zh">陕西省文化和旅游厅</div>
      <div class="en">Shaanxi Provincial Department of Culture and Tourism</div>
    </div>

    <div class="organize part">
      <div class="zh titlezh">协办</div>
      <div class="en titleen">CO-ORGANIZED BY</div>
      <div class="zh">中国对外文化集团有限公司</div>
      <div class="en">China Arts and Entertainment Group Ltd.</div>
      <div class="zh">陕西省人民对外友好协会</div>
      <div class="en">Shaanxi Provincial People's Association for Friendship with Foreign Countries</div>
      <div class="zh">西安市文化和旅游局</div>
      <div class="en">Xi'an Municipal Administration of Culture and Tourism</div>
    </div>
    <div class="support title">{{ this.$t('params.ts') }}: {{ this.$t('params.com') }}</div>
    <a href="http://beian.miit.gov.cn/" target="_blank" class="bei title">陕ICP备20001779号-1</a>
    <div class="visit title">访问量: {{ sum }}</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Footer',
  data() {
    return {
      sum: 120
    }
  },
  mounted() {
    axios.get('http://silkroad8th.scpece.org.cn:18990/api/v1/2').then(res => {
      this.sum = res.data;
    })
    axios.put('http://silkroad8th.scpece.org.cn:18990/api/v1/2')
  }
}
</script>

<style lang="less" scoped>
.footer {
  max-width: 1200PX;
  margin: auto;
  display: block;
  padding: 25px 32px;
  font-size: 12px;
  color: #74370F;
  font-family: SourceHanSansSC-Medium;
  text-align: center;
  background: url('http://image.wearetogether.com.cn/8th%2Fassets%2Ffooter-bg.jpg') repeat-y;
  background-size: cover;
  .titlezh{
    margin: 0px 0px 3px 0px;
    font-weight: 600;
  }
  .titleen{
    margin: 0px 0px 10px 0px;
    font-weight: 700;
  }
  .part {
    margin-bottom: 16px;
    .en {
      font-family: Arial;
      margin-bottom: 5px;
      margin-top: 3px;
    }
  }
  .bei {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #74370F;
  }
}
</style>
