<!--
 * @Author: 月魂
 * @Date: 2021-11-16 09:51:29
 * @LastEditTime: 2022-09-20 15:22:25
 * @LastEditors: 月魂
 * @Description: 美术展览
 * @FilePath: \silk-road\src\components\Exhibition.vue
-->
<template>
  <div class="exhibition" id="exhibition">
    <div class="exh-cont">
      <!-- <div class="title">{{ this.$t('exhibition.title') }}</div> -->
      <!-- <div class="sec-title">{{ this.$t('exhibition.subTitle') }}</div> -->
      <div class="works">
        <div class="header">
          <div class="title">
            <div class="zh">美术<span class="zh-text">展览</span></div>
            <div class="en">Fine Arts Exhibitions</div>
          </div>
        </div>
        <div class="list" v-for="item in getList" :key="item.id">
          <div class="item" @click="jumpToDetail(item.id)">
            <img :src="item.imgUrl" alt="" class="item-img">
            <div class="item-text">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-time">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Exhibition',
  computed: {
    getList: function() {
      return Object.values(this.$t('exhibition.list'));
    }
  },
  methods: {
    jumpToDetail(id) {
      this.$router.push(`/exhibition-detail/${id}`)
    }
  },
  
}
</script>

<style lang="less" scoped>
.exhibition {
  padding: 44px 16px 0;
  position: relative;
  color: #fff;
  .exh-cont {
    max-width: 1200PX;
    margin: auto;
    .works {
      display: flex;
      flex-wrap: wrap;
      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: SourceHanSerifCN-Bold;
          .zh {
            font-size: 42px;
            font-weight: bold;
            color: #4d4d4d;
            .zh-text {
              color: #FD4A22;
              font-size: 28px;
            }
          }
          .en {
            color: #4D4D4D;
            font-size: 14px;
            font-family: Arial;
            text-align: center;
          }
        }
      }
      .list {
        width: 100%;
        margin-top: 16px;
        .item {
          width: 100%;
          background: #fff;
          border-radius: 10px;
          overflow: hidden;
          .item-img {
            display: block;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;
          }
          .item-text {
            width: 100%;
            padding: 0 8px 14px;
            margin-top: 12px;
            font-family: SourceHanSansCN-Normal;
            .item-title {
              font-size: 16px;
              color: #333;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .item-time {
              font-size: 12px;
              color: #999;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
