<!--
 * @Author: 月魂
 * @Date: 2021-11-15 14:29:32
 * @LastEditTime: 2022-09-20 18:03:30
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \silk-road\src\App.vue
-->
<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view/>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      transitionName: ''
    }
  },
  created() {
  },
  // watch $route 决定使用哪种过渡
  watch:{
    '$route' (to, from) {
      if (to.path === '/home' && from.path === '/') {
        this.transitionName = 'fold-left'
      } else {
        this.transitionName = ''
      }
    }
  },
}
</script>

<style lang="less">
@font-face {
  font-family: newFont;
  src: url('http://image.wearetogether.com.cn/8th%2Fassets%2FITC%20Avant%20Garde%20CE%20Gothic%20Demi.ttf');
}
// 思源黑体
@font-face {
  font-family: SourceHanSansSC-Medium;
  src: url('http://image.wearetogether.com.cn/8th%2Fassets%2FSourceHanSansCN.otf');
}
// 思源宋体,用作标题
@font-face {
  font-family: SourceHanSerifCN-Bold;
  src: url('http://image.wearetogether.com.cn/8th%2Fassets%2FSourceHanSerifSC.otf');
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 1px;
  // word-break: break-all; // 英文单词强制不换行
}
html,body {
  max-width: 100%;
  overflow-x: hidden;
}
#app {
  font-family: PingFang SC, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
}

.fold-left-enter-active {
  animation-name: fold-left-in;
  animation-duration: 1s;
}
.fold-left-leave-active {
  animation-name: fold-left-out;
  animation-duration: 1s;
}
.fold-right-enter-active {
  animation-name: fold-right-in;
  animation-duration: 1s;
}
.fold-right-leave-active {
  animation-name: fold-right-out;
  animation-duration: 1s;
}
@keyframes fold-left-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fold-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fold-right-in{
  0% {
    width: 100%;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    /* visibility: visible; */
  }
  /*50% {
    transform: translate3d(50%, 0, 0);
  }*/
  100% {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fold-right-out  {
  0% {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  /*50% {
    transform: translate3d(-50%, 0 , 0);
  }*/
  100% {
    width: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    /* visibility: hidden; */
  }
}
// 时间线节点
.el-timeline-item__timestamp {
  color: #fff;
}

// 轮播图小圆点样式
.swiper-pagination-bullet, .swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
}

@media (min-width: 1000PX) {
  .swiper-pagination-bullet, .swiper-pagination-bullet-active {
    width: 16PX !important;
    height: 16PX !important;
  }
}

// 修改vant导航栏颜色
.van-nav-bar {
  background: #fcf8ed !important;
  max-width: 1200PX !important;
  margin: auto;
}
.van-nav-bar .van-icon {
  color: #333 !important;
}
.van-nav-bar__title {
  font-family: SourceHanSerifCN-Bold;
  font-size: 20px !important;
  color: #333 !important;
}

</style>
