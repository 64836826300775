<!--
 * @Author: 月魂
 * @Date: 2022-09-09 11:25:48
 * @LastEditTime: 2022-09-20 15:18:39
 * @LastEditors: 月魂
 * @Description: 精彩资讯
 * @FilePath: \silk-road\src\components\News.vue
-->
<template>
  <div class="news" id="news" v-if="getNews.length">
    <div class="news-cont">
      <div class="works">
        <div class="header">
          <div class="title">
            <div class="zh">新闻<span class="zh-text">资讯</span></div>
            <div class="en">News</div>
          </div>
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in getNews" :key="index" @click="jumpToDetail(item)">
            <img :src="item.imgUrl" alt="" class="swipe-img">
            <div class="swipe-text">{{ item.title }}</div>
          </van-swipe-item>
        </van-swipe>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Swipe, SwipeItem } from 'vant'
Vue.use(Swipe).use(SwipeItem)

export default {
  name: 'News',
  computed: {
    getNews: function() {
      return Object.values(this.$t('news.list'));
    }
  },
  methods: {
    jumpToDetail(item) {
      window.location.href = item.link
    }
  },
  
}
</script>

<style lang="less" scoped>
.news {
  padding: 44px 16px 0;
  position: relative;
  .news-cont {
    max-width: 1200PX;
    margin: auto;
    font-size: 22px;
    .works {
      display: flex;
      flex-wrap: wrap;
      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: SourceHanSerifCN-Bold;
          .zh {
            font-size: 42px;
            font-weight: bold;
            color: #4d4d4d;
            .zh-text {
              color: #FD4A22;
              font-size: 28px;
            }
          }
          .en {
            color: #4D4D4D;
            font-size: 14px;
            font-family: Arial;
            text-align: center;
          }
        }
      }
      .my-swipe {
        width: 100%;
        margin-top: 16px;
        .van-swipe-item {
          width: 100%;
          color: #fff;
          border-radius: 10px;
          overflow: hidden;
          .swipe-img {
            display: block;
            width: 100%;
            height: 192px;
          }
          .swipe-text {
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            padding: 0 12px;
            font-size: 14px;
            font-family: SourceHanSansSC-Medium;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: rgba(0,0,0,0.6);
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
