/*
 * @Author: 月魂
 * @Date: 2022-09-17 08:32:37
 * @LastEditTime: 2022-09-20 18:13:36
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \silk-road\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n/i18n'
import { Timeline, TimelineItem, Card } from 'element-ui'
import './lib/rem'
import wx from 'weixin-js-sdk';
import axios from 'axios'
// import VConsole from 'vconsole'

// eslint-disable-next-line no-unused-vars
// const vConsole = new VConsole();

Vue.config.productionTip = false
Vue.use(Timeline).use(TimelineItem).use(Card)

const wxShareConfig = () => {
  axios.get("http://silkroad8th.scpece.org.cn:18990/wx/signature?link="+encodeURIComponent(window.location.href),{
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }).then(response => {
    wx.config(
      {
        debug:false,
        appId:"wx6aaf01a2ed23aab7", // 必填，公众号的唯一标识  
        timestamp:response.data.timestamp, // 必填，生成签名的时间戳  
        nonceStr:response.data.noncestr, // 必填，生成签名的随机串  
        signature:response.data.signature,// 必填，签名  
        jsApiList:['updateAppMessageShareData','updateTimelineShareData']    
      }
    );
  }).catch((err) => console.log(err, 'config'));
}

const getUrl = () => {
  const hasParam = window.location.href.indexOf('?') !== -1
  const hasShare = window.location.href.indexOf('isShare') !== -1
  let url = window.location.href
  if (hasParam) {
    if (!hasShare) url += '&isShare=true'
  } else {
    url += '?isShare=true'
  }
  return url
}

Vue.prototype.wxShareDef = () => {
  const shareUrl = getUrl()
  wxShareConfig();
  wx.ready(() => {
    console.log('in ready')
    //分享给朋友
    wx.updateAppMessageShareData({  
      title: '第八届丝绸之路国际艺术节', // 分享标题  
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致  
      desc: "邀请您访问第八届丝绸之路国际艺术节",
      imgUrl: 'http://image.wearetogether.com.cn/8th%2Fshare_logo.png', // 分享图标  
    });     
    //分享朋友圈  
    wx.updateTimelineShareData({  
      title: '第八届丝绸之路国际艺术节', // 分享标题  
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致  
      desc: "邀请您访问第八届丝绸之路国际艺术节",
      imgUrl: 'http://image.wearetogether.com.cn/8th%2Fshare_logo.png', // 分享图标 
    });  
  })
  wx.error((err) => {
    console.log(err, 'in error')
  })
}

Vue.prototype.wxShare = (title, desc, imgUrl) => {
  const shareUrl = getUrl()
  wxShareConfig();
  wx.ready(() => {
    console.log('in ready')
    //分享给朋友
    wx.updateAppMessageShareData({
      title: title || '第八届丝绸之路国际艺术节', // 分享标题  
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致  
      desc: desc,
      imgUrl: imgUrl || 'http://image.wearetogether.com.cn/8th%2Fshare_logo.png', // 分享图标  
    });     
    //分享朋友圈  
    wx.updateTimelineShareData({  
      title: title || '第八届丝绸之路国际艺术节', // 分享标题  
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致  
      desc: desc,
      imgUrl: imgUrl || 'http://image.wearetogether.com.cn/8th%2Fshare_logo.png', // 分享图标   
    });  
  })
  wx.error((err) => {
    console.log(err, 'in error')
  })
}

Vue.prototype.wxShareDiy = (friend, circle) => {
  const shareUrl = getUrl()
  wxShareConfig();
  wx.ready(() => {
    console.log('in ready')
    //分享给朋友
    wx.updateAppMessageShareData({
      title: friend.title || '第八届丝绸之路国际艺术节', // 分享标题  
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致  
      desc: friend.desc,
      imgUrl: friend.imgUrl || 'http://image.wearetogether.com.cn/8th%2Fshare_logo.png', // 分享图标  
    });     
    //分享朋友圈  
    wx.updateTimelineShareData({
      title: circle.title || '第八届丝绸之路国际艺术节', // 分享标题  
      link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致  
      desc: circle.desc,
      imgUrl: circle.imgUrl || 'http://image.wearetogether.com.cn/8th%2Fshare_logo.png', // 分享图标   
    });  
  })
  wx.error((err) => {
    console.log(err, 'in error')
  })
}


Vue.prototype.wxShareConfig = wxShareConfig;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
