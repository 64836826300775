/*
 * @Author: 月魂
 * @Date: 2022-09-02 13:47:46
 * @LastEditTime: 2022-09-07 15:09:05
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \silk-road\src\i18n\langs\index.js
 */
import en from './en'
import zh from './zh'
export default {
  en,
  zh
}
