/*
 * @Author: 月魂
 * @Date: 2022-09-02 13:47:46
 * @LastEditTime: 2022-09-20 10:16:03
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \silk-road\src\i18n\i18n.js
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'

Vue.use(VueI18n)
const language = localStorage.getItem('lang')
const i18n = new VueI18n({
  locale: language || 'zh', // 此处切换语言
  fallbackLocale: 'zh',
  messages
})

export default i18n
