<!--
 * @Author: 月魂
 * @Date: 2021-11-15 16:10:37
 * @LastEditTime: 2022-09-15 13:12:50
 * @LastEditors: 月魂
 * @Description: 关于艺术节
 * @FilePath: \silk-road\src\components\MyArt.vue
-->

<template>
  <div class="box" @click="jumpToArt">
    <div class="cont">
      <div class="title">{{ this.$t('Introduction') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyArt',
  methods: {
    jumpToArt() {
      this.$router.push('/intro')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 16px;
  margin-bottom: 8px;
  .cont {
    width: 100%;
    max-width: 1200PX;
    height: 80px;
    background-image: url('http://image.wearetogether.com.cn/8th%2Fassets%2Fintro%2Fdate-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;
    margin: auto;
    padding: 0 16px;
    .title {
      font-size: 18px;
      font-family: SourceHanSerifCN-Bold;
      color: #FFECC2;
      text-align: right;
      line-height: 80px;
    }
  }
}
</style>
