<!--
 * @Author: 月魂
 * @Date: 2021-11-15 14:29:32
 * @LastEditTime: 2022-09-20 11:05:28
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \silk-road\src\views\Home.vue
-->
<template>
  <div class="home">
    <transition name="fade">
      <div ref="header" v-if="isShow" class="header">
        <div class="left">
          <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Flogo.png" alt="" class="logo">
        </div>
        <ul class="menu" :class="showMenu ? 'showMenu' : ''" @click="clickToShowMenu">
          <li class="t"></li>
          <li class="m"></li>
          <li class="b"></li>
        </ul>
      </div>
    </transition>
    <van-overlay :show="showMenu" @click="showMenu = false" :z-index="110">
      <div class="list" @click.stop>
        <div class="item" @click="jumpToPart('schedule')" v-if="getList.length">今日活动</div>
        <div class="item" @click="jumpToPart('news')">新闻资讯</div>
        <div class="item" @click="jumpToPart('perform')">文艺演出</div>
        <div class="item" @click="jumpToPart('exhibition')">美术展览</div>
        <div class="item" @click="jumpToPart('activity')">专题活动</div>
      </div>
    </van-overlay>
    
    <div class="top">
      <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Fmobile-header.jpg" alt="" class="top-img top-img-mobile">
      <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Fpc-header.jpg" alt="" class="top-img top-img-pc">
      <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Fcover.png" alt="" class="top-cover top-img-mobile">
      <div class="top-date">
        <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Fdate.png" alt="" class="date-img">
        <div class="date-title">国际艺术节</div>
        <div v-if="!showEnd">
          <div class="date-time" v-if="getLang === 'zh'">第<span class="date-now">{{ day }}</span>天</div>
          <div class="date-time date-time-en" v-else>Day<span class="date-now">{{ day }}</span></div>
        </div>
        <div class="end" v-else>End</div>
      </div>
    </div>

    <div class="lang">
      <div class="changeLang" @click="changeLang">{{ isZh ? '中文' : 'EN' }}</div>
    </div>
    <!-- 日程 -->
    <MySchedule :list="getList"></MySchedule>
    <!-- 精彩资讯 -->
    <News></News>
    <!-- 文艺演出 -->
    <Perform></Perform>
    <!-- 美术展览 -->
    <Exhibition></Exhibition>
    <!-- 专题活动 -->
    <Activities></Activities>
    <!-- 全部日程列表 -->
    <AllSchedule></AllSchedule>
    <!-- 关于艺术节 -->
    <MyArt></MyArt>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Vue from 'vue';
import { Overlay } from 'vant';
import dayjs from 'dayjs'
import MyArt from '../components/MyArt.vue'
import MySchedule from '../components/MySchedule.vue'
import Perform from '../components/Perform.vue'
import Exhibition from '../components/Exhibition.vue'
import News from '../components/News.vue'
import Activities from '../components/Activities.vue'
import AllSchedule from '../components/AllSchedule.vue'
import Footer from '../components/Footer.vue'

Vue.use(Overlay);

export default {
  name: 'Home',
  data() {
    return {
      isShow: false,
      showMenu: false,
      day: '1',
      showEnd: false,
      isZh: true
    }
  },
  components: {
    MyArt,
    MySchedule,
    Perform,
    Exhibition,
    News,
    Activities,
    AllSchedule,
    Footer
  },
  computed: {
    getLang: function() {
      return this.$i18n.locale;
    },
     getList: function() {
      let list = [];
      Object.values(this.$t('perform.list')).forEach(item => {
        if (dayjs().isAfter(`${item.startTime} 00:00:00`) && dayjs().isBefore(`${item.endTime} 23:59:59`)) {
          item.showType = 'perform';
          list.push(item);
        }
      })
      
      Object.values(this.$t('exhibition.list')).forEach(item => {
        if (dayjs().isAfter(`${item.startTime} 00:00:00`) && dayjs().isBefore(`${item.endTime} 23:59:59`)) {
          item.showType = 'exhibition';
          list.push(item);
        }
      })

      Object.values(this.$t('activities.list')).forEach(item => {
        if (dayjs().isAfter(`${item.startTime} 00:00:00`) && dayjs().isBefore(`${item.endTime} 23:59:59`)) {
          item.showType = 'activity';
          list.push(item);
        }
      })

      // 此处对list进行排序整理
      list.sort((a, b) => dayjs(a.endTime).diff(dayjs(a.startTime), 'day') - dayjs(b.endTime).diff(dayjs(b.startTime), 'day'))

      return list;
    }
  },
  mounted() {
    this.isZh = this.$i18n.locale === 'zh'
    let num = dayjs(dayjs().format('YYYY-MM-DD')).diff('2022-09-17', 'day');
    if (num > 6) {
      this.showEnd = true;
    } else if (num <= 0) {
      num = 1
    }
    this.day = num
    window.addEventListener('scroll', this.handleScroll)
    this.wxShareDef();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    changeLang() {
      this.isZh = !this.isZh
      const lang = this.isZh ? 'zh' : 'en'
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang);
    },
    clickToShowMenu() {
      this.showMenu = !this.showMenu;
    },
    jumpToPart(name) {
      this.showMenu = false
      const dom =document.getElementById(name);
      dom.scrollIntoView({ behavior: 'smooth' });
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const headerFixed = scrollTop > 200;
      if (this.isShow && headerFixed) return
      if(headerFixed) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.home {
  background: url('http://image.wearetogether.com.cn/8th%2Fassets%2Fhome-bg.jpg') repeat;
  background-size: contain;
  position: relative;
  .header {
    width: 100%;
    max-width: 1200PX;
    height: 44px;
    line-height: 44px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 111;
    // background: #fff;
    background: floralwhite;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f6f6f6;
    box-shadow: 0 1px 5px #aaa;
    .left {
      .logo {
        display: block;
        width: 90px;
      }
    }
    .menu {
      width: 20px;
      height: 20px;
      transition: all .5s;
    }
    .menu li {
      width: 20px;
      height: 2px;
      background-color: #000;
      transition: all .5s;
    }
    .menu .m {
      transform: translateY(6px);
    }
    .menu .b {
      transform: translateY(12px);
    }
    .showMenu .t {
      transform: translateY(12px) rotate(90deg);
    }
    .showMenu .m {
      transform: translateY(8.5px) scaleX(0);
    }
    .showMenu .b {
      transform: translateY(8px);
    }
    .showMenu {
      transform: rotate(225deg);
    }
  }
  .list {
    width: 100%;
    max-width: 1200PX;
    
    // background: #fff;
    background: floralwhite;
    border-radius: 0px 0px 20px 20px;
    padding: 0 16px;
    overflow: hidden;
    position: fixed;
    top: 44px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 111;
    .item {
      width: 100%;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular;
      color: #4D4D4D;
      text-align: center;
      padding: 14px 0;
      border-bottom: 1px solid #f6f6f6;
    }
  }
  .top {
    max-width: 1200PX;
    margin: auto;
    position: relative;
    .top-img {
      display: block;
      width: 100%;
    }
    .top-img-pc {
      display: none;
    }
    @media (min-width: 1200PX) {
      .top-img-pc {
        display: block;
      }
      .top-img-mobile {
        display: none;
      }
    }
    .top-cover {
      display: block;
      width: 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: 1;
    }
    .top-date {
      display: none;
      width: 110px;
      height: 110px;
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 2;
      font-family: SourceHanSansSC-Medium;
      .date-img {
        display: block;
        width: 100%;
      }
      .date-title {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 12px;
        color: #FFF0CF;
        text-align: center;
        line-height: 36px;
      }
      .end {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
        line-height: 36px;
        text-align: center;
        font-size: 26px;
        color: #CB2901;
        margin: 0 8px;
        font-weight: bold;
      }
      .date-time {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 12px;
        color: #4D4D4D;
        line-height: 36px;
        text-align: center;
        .date-now {
          font-size: 26px;
          color: #CB2901;
          margin: 0 8px;
          font-weight: bold;
        }
      }
      .date-time-en {
        font-size: 14px;
      }
    }
  }

  .lang {
    position: absolute;
    top: 8px;
    left: 16px;
    z-index: 1;
    .changeLang {
      width: 50px;
      height: 30px;
      border: 1px solid #eee;
      color: #eee;
      border-radius: 6px;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
