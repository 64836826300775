/*
 * @Author: 月魂
 * @Date: 2022-09-02 13:47:46
 * @LastEditTime: 2022-09-20 10:50:23
 * @LastEditors: 月魂
 * @Description: zh
 * @FilePath: \silk-road\src\i18n\langs\zh.js
 */
const zh = {
  title: '丝绸之路国际艺术节',
  params: {
    host: '主办单位', // 主办
    undertake: '承办单位', // 承办
    coOrganized: '协办单位', // 协办
    organize: '组织单位', // 组织
    support: '支持单位', // 支持单位
    thanks: '鸣谢单位',
    address: '演出地点',
    exhibitionAddress: '展览地点',
    activityAddress: '举办地点',
    time: '演出时间',
    exhibitionTime: '展览时间',
    activityTime: '时间',
    team: '演出团队',
    theater: '剧团介绍',
    history: '往届回顾',
    watchOnline: '点击在线观看',
    ts: '技术支持',
    com: '陕西文一国际文化发展有限公司',
    review: '精彩回顾',
    clip: '精彩剪辑',
    times: '次'
  },
  // 关于艺术节
  intro: {
    title: '第八届丝绸之路国际艺术节介绍',
    name: '第八届丝绸之路国际艺术节',
    des: ["丝绸之路国际艺术节是经党中央、国务院批准, 由文化和旅游部与陕西省人民政府共同主办、陕西省文化和旅游厅承办的“一带一路”国家级文化艺术盛会。自2014年创办至今, 已成功举办七届，并永久落户陕西，是陕西建设丝绸之路经济带新起点的重要品牌，国家“一带一路”文化合作与交流的重点项目，展示中华文化和弘扬中国精神的重要平台，促进丝路沿线国家（地区）文明互鉴和民心相通的重要活动。", "第八届丝绸之路国际艺术节定于9月17日至23日在西安举办。本届艺术节将深入贯彻落实习近平总书记关于推动共建“一带一路”高质量发展的重要讲话精神, 以“共谱和平之曲 高唱丝路欢歌”为主题, 举办开闭幕式、文艺演出、美术展览、线上展播, 开展拉美艺术季、国际儿童戏剧周、泰国文化日、2022西安国际数字互动娱乐文化周、非遗活动展演等五项专题活动, 搭建“一带一路”沿线国家民心相通的桥梁, 以文化交流促传播, 以展览展示促了解，以文明交融促和谐，推动共建“一带一路”高质量发展取得新成效。", "热烈欢迎世界各国艺术家和海内外嘉宾共襄丝路文化盛事，共享丝路文化盛宴！"]
  },
  share: {
    title: "第八届丝绸之路国际艺术节",
    subTitle: "邀请您访问第八届丝绸之路国际艺术节",
    imgUrl: "http://image.wearetogether.com.cn/8th%2Fshare_logo.png"
  },
  // 日程
  schedule: {
    title: '活动日程',
  }
}

export default zh
