<!--
 * @Author: 月魂
 * @Date: 2022-09-08 09:54:03
 * @LastEditTime: 2022-09-20 15:21:27
 * @LastEditors: 月魂
 * @Description: 首页专题活动版块
 * @FilePath: \silk-road\src\components\Activities.vue
-->
<template>
  <div class="activity" id="activity">
    <div class="perform-cont">
      <div class="box">
        <div class="header">
          <div class="title">
            <div class="zh">专题<span class="zh-text">活动</span></div>
            <div class="en">Project Activities</div>
          </div>
        </div>
        <div class="list" v-for="item in getList" :key="item.id">
          <div class="item" @click="jumpToDetail(item)">
            <!-- <div class="item-header">
              <div class="zh"><img src="../assets/a1.png" alt="" class="icon">测试</div>
              <div class="en">test</div>
            </div> -->
            <div class="card">
              <img :src="item.imgUrl" alt="" class="card-img">
              <div class="card-text">
                <div class="card-title">{{ item.title }}</div>
                <div class="card-time">{{ item.time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Activities',
  computed: {
    getList: function() {
      return Object.values(this.$t('activities.list'));
    }
  },
  methods: {
    jump() {
      this.$router.push('/activities')
    },
    jumpToDetail(item) {
      // 如果有list则进入列表页，如果有performList则进入指定剧目列表页，否则进入详情页
      if(item.list) {
        this.$router.push(`/activity-list/${item.id}`);
      } else if (item.performList) {
        this.$router.push({ path: '/specify-list', query: { title: item.title, playType: item.playType } });
      } else if (item.cont) {
         if (item.type) {
          this.$router.push(`/${item.type}-detail/${item.linkId}`)
        } else {
          this.$router.push(`/activity-detail/${item.id}`)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.activity {
  position: relative;
  padding: 44px 16px 0;
  .perform-cont {
    max-width: 1200PX;
    margin: auto;
    .box {
      width: 100%;
      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: SourceHanSerifCN-Bold;
          .zh {
            font-size: 42px;
            font-weight: bold;
            color: #4d4d4d;
            .zh-text {
              color: #FD4A22;
              font-size: 28px;
            }
          }
          .en {
            color: #4D4D4D;
            font-size: 14px;
            font-family: Arial;
            text-align: center;
          }
        }
      }
      .list {
        width: 100%;
        .item {
          width: 100%;
          margin-top: 16px;
          .item-header {
            width: 100%;
            text-align: center;
            .zh {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                display: inline-block;
                width: 36px;
                height: 36px;
                margin-right: 4px;
              }
            }
            .en {
              color: #333333;
              font-size: 14px;
              font-family: Arial;
              margin-top: 6px;
            }
          }
          .card {
            width: 100%;
            margin-top: 24px;
            border-radius: 10px;
            overflow: hidden;
            background: #fff;
            .card-img {
              display: block;
              width: 100%;
              border-radius: 10px;
              overflow: hidden;
            }
            .card-text {
              width: 100%;
              padding: 8px 14px;
              font-family: SourceHanSansCN-Normal;
              .card-title {
                font-size: 16px;
                color: #333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .card-time {
                font-size: 12px;
                color: #999;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .fir {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
        padding: 0 10px;
        @media (min-width: 1200PX) {
          padding: 0;
        }
        .item {
          position: relative;
          width: 49%;
          .pina {
            display: block;
            width: 100%;
          }
          .text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            // background-color: rgba(0, 0, 0, 0.8);
            background-image: linear-gradient(to right, #f39b03, #984081);
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            padding: 6px 10px;
          }
        }
      }
      .sec {
        margin-top: 10px;
        padding: 15px 10px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #f19e55;
          z-index: -2;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('../assets/bg.png');
          background-position: 0 10px;
          background-repeat: no-repeat;
          background-size: 100% auto;
          z-index: -1;
          opacity: 0.1;
        }
        .cont {
          padding: 10px;
          color: #fff;
          line-height: 2;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
