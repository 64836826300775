<!--
 * @Author: 月魂
 * @Date: 2021-12-02 18:50:42
 * @LastEditTime: 2022-09-16 16:52:10
 * @LastEditors: 月魂
 * @Description: 全部日程
 * @FilePath: \silk-road\src\components\AllSchedule.vue
-->
<template>
  <div class="box" @click="$router.push('/schedule/list')">
    <div class="cont">
      <div class="title">{{ this.$t('schedule.title') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllSchedule'
}
</script>

<style lang="less" scoped>
.box {
  padding: 16px;
  .cont {
    width: 100%;
    max-width: 1200PX;
    height: 80px;
    background-image: url('http://image.wearetogether.com.cn/8th/assets/intro-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;
    margin: auto;
    padding: 0 16px;
    .title {
      font-size: 18px;
      font-family: SourceHanSerifCN-Bold;
      color: #FFECC2;
      text-align: right;
      line-height: 80px;
    }
  }
}
</style>
