<!--
 * @Author: 月魂
 * @Date: 2021-11-19 15:18:10
 * @LastEditTime: 2022-09-17 11:10:21
 * @LastEditors: 月魂
 * @Description: 日程
 * @FilePath: \silk-road\src\components\MySchedule.vue
-->
<template>
  <div class="box" v-if="list.length" id="schedule">
    <div class="header">
      <div class="title">
        <div class="zh">今日<span class="zh-text">活动</span></div>
        <div class="en">Daily Highlights</div>
      </div>
      <!-- <div class="changeLang" @click="changeLang">{{ isZh ? '中文' : 'EN' }}</div> -->
    </div>
    
    <div class="list">
      <div class="item" v-for="(s, index) in list" :key="index" @click="jumpToDetail(s)">
        <img :src="s.imgUrl==''?'http://image.wearetogether.com.cn/8th%2Fnews%2F%E5%80%92%E8%AE%A1%E6%97%B6.jpg':s.imgUrl" alt="" class="dateItem">
        <div class="perform-text">
          <div class="perform-title">{{ s.title }}</div>
          <div class="perform-time">{{ s.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dayjs from 'dayjs'

export default {
  name: 'MySchedule',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isZh: true
    }
  },
  mounted() {
    this.isZh = this.$i18n.locale === 'zh'
  },
  methods: {
    changeLang() {
      this.isZh = !this.isZh
      const lang = this.isZh ? 'zh' : 'en'
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang);
    },
    jump() {
      this.$router.push('/schedule')
    },
    jumpToDetail(params) {
      if (params.showType === 'activity') {
        // 此处要判断是进入详情页还是列表页
        // 如果有list则进入列表页，如果有performList则进入指定剧目列表页，否则进入详情页
        if(params.list) {
          this.$router.push(`/activity-list/${params.id}`);
        } else if (params.performList) {
          this.$router.push({ path: '/specify-list', query: { title: params.title, playType: params.playType } });
        } else if (params.cont) {
          if (params.type) {
            this.$router.push(`/${params.type}-detail/${params.linkId}`)
          } else {
            this.$router.push(`/activity-detail/${params.id}`)
          }
        }
        
      } else {
        this.$router.push(`/${params.showType}-detail/${params.id}`)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 44px 16px 0;
  position: relative;
  color: #fff;
  max-width: 1200PX;
  margin: auto;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-family: SourceHanSerifCN-Bold;
      .zh {
        font-size: 42px;
        font-weight: bold;
        color: #4d4d4d;
        .zh-text {
          color: #FD4A22;
          font-size: 28px;
        }
      }
      .en {
        color: #4D4D4D;
        font-size: 14px;
        font-family: Arial;
        text-align: center;
      }
    }
    .changeLang {
      width: 76px;
      height: 48px;
      border: 2px solid #EE5D0B;
      color: #EE5D0B;
      border-radius: 20px;
      font-size: 18px;
      line-height: 48px;
      text-align: center;
      font-weight: bold;
    }
  }
  .list {
    max-width: 1200PX;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 1200PX) {
      padding: 10px 0;
    }
    .item {
      width: 164px;
      .dateItem {
        display: block;
        width: 100%;
        height: 92px;
        border-radius: 10px;
        overflow: hidden;
      }
      .perform-text {
        width: 100%;
        margin-top: 6px;
        color: #4D4D4D;
        font-family: SourceHanSansSC-Medium;
        line-height: 1;
        .perform-title {
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .perform-time {
          color: #999;
          font-size: 12px;
          margin-top: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      margin-bottom: 6px;
    }
    // .item {
    //   width: 48%;
    //   position: relative;
    //   .dateItem {
    //     display: block;
    //     width: 100%;
    //   }
    //   .text {
    //     // position: absolute;
    //     // bottom: 0;
    //     // left: 0;
    //     width: 100%;
    //     // background-image: linear-gradient(to right, #f39b03, #984081);
    //     background-color: rgba(0, 0, 0, 0.3);
    //     color: #fff;
    //     font-size: 12px;
    //     padding: 3px 10px 0;
    //     div {
    //       white-space: nowrap;
    //       overflow: hidden;
    //       text-overflow: ellipsis;
    //       line-height: 1.5;
    //     }
    //   }
    //   margin-bottom: 6px;
    // }
  }
}

#schedule{
  padding-top: 0px !important;
}
</style>
